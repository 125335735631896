import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto hash digest`}</strong>{` -- generate a hash digest of a file or directory`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto hash digest <file-or-directory>...
[--alg=<algorithm>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto hash digest`}</strong>{` generates a hash digest for a given file or
directory. For a file, the output is the same as tools like 'shasum'. For
directories, the tool computes a hash tree and outputs a single hash digest.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto hash`}</strong>{`.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`file-or-directory`}</inlineCode>{`
The path to a file or directory to hash.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`
The hash algorithm to use.`}</p>
    <p><inlineCode parentName="p">{`algorithm`}</inlineCode>{` must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha1`}</strong>{` (or sha): SHA-1 produces a 160-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha224`}</strong>{`: SHA-224 produces a 224-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha256`}</strong>{` (default): SHA-256 produces a 256-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha384`}</strong>{`: SHA-384 produces a 384-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha512`}</strong>{`: SHA-512 produces a 512-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha512-224`}</strong>{`: SHA-512/224 uses SHA-512 and truncates the output to 224 bits`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha512-256`}</strong>{`: SHA-512/256 uses SHA-512 and truncates the output to 256 bits`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`md5`}</strong>{` (requires --insecure): MD5 produces a 128-bit hash value`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      